<template>
  <section id="audit-log">
    <div class="row">
      <fieldset class="form-group">
        <div class="input-group col-12 col-md-7 col-lg-5 col-xl-3">
          <label>Filter by day</label>
          <datepicker v-model="pickedDate" inputFormat="dd/MM/yyyy" />
        </div>
      </fieldset>
    </div>
    <div class="row">
      <div class="card col-md-12">
        <div class="card-body">
          <table class="table">
            <thead>
            <tr>
              <th scope="col">ID</th>
              <th scope="col">Date / Time</th>
              <th scope="col">User</th>
              <th scope="col">Action</th>
            </tr>
            </thead>
            <tbody>
            <tr class="log" v-for="log in logs">
              <th scope="row">{{ log.action_log_id }}</th>
              <td>{{ log.timestamp }}</td>
              <td>{{ log.username }}</td>
              <td>{{ log.name }}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import APIService from "@/services/APIService";
import UtilService from "@/services/UtilService";

export default {
  name: "Logs",
  data() {
    return {
      logs: [],
      filter: {
        fields: {
          request_method: ['put', 'patch', 'post', 'delete'],
          slug: {'not': 'data_post'}
        },
        paginate: {
          start: 0,
          limit: 1000
        },
        order: {
          timestamp: 'desc'
        }
      },
      pickedDate: new Date(),
      error: null
    }
  },
  methods: {
    setDateFilter: function(date) {
      const min = new Date(date.getTime());
      min.setHours(0,0,0,0);

      const max = new Date(date.getTime());
      max.setHours(23,59,59,999);

      this.filter.fields.timestamp = {
        min: min.toISOString(),
        max: max.toISOString()
      }
    },
    refreshLogs: async function() {
      this.logs = (await APIService.getAuditLogs(this.filter))
          .map(log => {
            log.name = UtilService.stringifySlug(log.slug);
            log.timestamp = UtilService.formatSqlDateTimeString(log.timestamp);
            return log;
          });
    }
  },
  watch: {
    pickedDate: function(val) {
      this.setDateFilter(val);
      this.refreshLogs();
    }
  },
  async created() {
    this.setDateFilter(new Date());
    await this.refreshLogs();
  }
}
</script>

<style scoped>
#audit-log {
  min-height: 350px;
  margin: 0;
  padding: 0;
}
</style>
