export default {
    stringifySlug: slug => slug
        .replace(/_/g, ' ')
        .replace(/\w\S*/g, w => w.replace(/^\w/, c => c.toUpperCase())),

    sqlDateTimeStringToDate: dateString => new Date(dateString.replace(' ', 'T')+'Z'),
    dateToSqlDateTimeString: date => date.toISOString().replace('T', ' ').substring(0,19),
    dateToSqlDateString: date => date.toISOString().replace('T', ' ').substring(0,10),
    formatSqlDateTimeString: dateString => new Date(dateString.replace(' ', 'T')+'Z').toLocaleString(),
    formatSqlDateString: dateString => new Date(dateString.replace(' ', 'T')+'Z').toLocaleDateString(),
};
